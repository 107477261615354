<template>
  <footer id="footer" class="footer">
    <ul class="footer-links">
      <li class="footer-links-item">
        <VKIcon/>
        <a
          class="footer-links-item__link"
          href="https://vk.com/pinpocketai"
          target="_blank"
        >
          Сообщество ВКонтакте
        </a>
      </li>
      <li class="footer-links-item">
        <TGIcon/>
        <a
          class="footer-links-item__link"
          href="https://t.me/PinPocketAI"
          target="_blank"
        >
          Telegram-канал
        </a>
      </li>
      <li class="footer-links-item">
        <PhoneIcon/>
        <a
          class="footer-links-item__link"
          href="tel:+79021532682"
          target="_blank"
        >
          +7(902)153-26-82
        </a>
      </li>
      <li class="footer-links-item">
        <MailIcon/>
        <a
          class="footer-links-item__link"
          href="mailto:pinpocket@yandex.ru"
          target="_blank"
        >
          pinpocket@yandex.ru
        </a>
      </li>
    </ul>
    <div class="footer-data">
      <span class="footer-data__text">ООО “Психолог в кармане”</span>
      <span class="footer-data__text">ИНН	6317163598</span>
      <span class="footer-data__text">ОГРН 1236300013070</span>
      <div class="footer-data-support">
        <span class="footer-data__text footer-data__text-bold">При финансовой поддержке фонда</span>
        <a
          class="footer-data__image-link"
          href="https://fasie.ru/"
          target="_blank"
        >
          <img class="footer-data__image" alt="Фонд содействия инновациям" :src="ipfLogo">
        </a>
      </div>
    </div>
    <div class="footer-bottom">
      <span>Самара 2024</span>
      <a class="footer-bottom__link" href="/privacy_policy_pinpocket">Политика конфиденциальности</a>
    </div>
  </footer>
</template>

<script>
  import VKIcon from "@/assets/svg/icons/footer/VKIcon.vue";
  import TGIcon from "@/assets/svg/icons/footer/TGIcon.vue";
  import PhoneIcon from "@/assets/svg/icons/footer/PhoneIcon.vue";
  import MailIcon from "@/assets/svg/icons/footer/MailIcon.vue";

  export default {
    name: 'Footer',
    components: {
      VKIcon,
      TGIcon,
      PhoneIcon,
      MailIcon
    },
    data: () => {
      return {
        ipfLogo: window.innerWidth > 960
            ? 'svg/ipf-logo.svg'
            : 'svg/mobile/ipf-logo-mobile.svg'
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "./index.scss";
</style>