<template>
    <div v-if="showPopup" class="popup-overlay">
        <div class="popup-content">
            <p>
                Мы используем файлы cookie для улучшения вашего опыта на сайте.
            </p>
            <div class="controls">
                <a href="/privacy_policy_pinpocket.pdf">Подробнее</a>
                <button @click="acceptCookies">Принять</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showPopup: true, // Показывать попап, если куки не установлены
        };
    },
    mounted() {
        // Проверяем, есть ли кука privacypolicy
        if (this.getCookie('privacypolicy') === 'true') {
            this.showPopup = false; // Если кука установлена, скрываем попап
        }
    },
    methods: {
        acceptCookies() {
            // Устанавливаем куку privacypolicy со значением true
            this.setCookie('privacypolicy', 'true', 365); // Срок действия: 365 дней
            this.showPopup = false; // Скрываем попап после согласия
        },
        setCookie(name, value, days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            const expires = `expires=${date.toUTCString()}`;
            document.cookie = `${name}=${value};${expires};path=/`;
        },
        getCookie(name) {
            const nameEQ = `${name}=`;
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                let cookie = cookies[i].trim();
                if (cookie.indexOf(nameEQ) === 0) {
                    return cookie.substring(nameEQ.length, cookie.length);
                }
            }
            return null;
        },
    },
};
</script>

<style scoped>
.controls {
    display: flex;
    gap: 12px;
    justify-content: center;
    width: 100%;
}
.popup-overlay {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.popup-content h3 {
    margin-bottom: 10px;
}

.popup-content p {
    margin-bottom: 20px;
    font-size: 24px;
}

.popup-content button {
    padding: 10px 20px;
    background: #42b983;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 20px;
    line-height: 24px;
}

.popup-content button:hover {
    background: #35a370;
}

a {
    padding: 10px 20px;
    background: #42b983;
    color: white;
    border: none;
    font-size: 20px;
    border-radius: 4px;
    cursor: pointer;
}

a:hover {
    background: #35a370;
}
</style>